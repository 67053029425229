import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'normalize.css';
import './src/global.css';

export function onInitialClientRender() {
  setTimeout(() => {
    const loader = document.getElementById('___loader');
    if (loader) {
      loader.style.display = 'none';
    }
  }, 200);
}
